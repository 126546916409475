import React from 'react'
import { 
    Box,
    Button, Card, CardContent, Divider, FormGroup, Grid, ImageList, ImageListItem, ImageListItemBar, List, ListItem, 
    ListItemText, Paper, Popover, styled, TextField, Typography 
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { IChatMessage } from 'types/chat.type'
import Base from 'pages/Base'
import Typing from 'components/Typing'
import { BoxChatContainer, BoxChatHumanMessage, BoxChatIAMessage, TextareaAutosize, TypographyArticle } from 'pages/styled/Chat.styled';
import { useLoaderData, useParams } from 'react-router-dom';
import { addKeywordApi } from 'services/users';
import { generateArticleApi } from 'services/generate';
import { ContentTypeGenerateArticle, ArticleGenerated, iGenerateArticle } from 'types/generated.type';

const CardArticle = styled(Card)(({ theme }) => ({
    padding: '10px',
    margin: '1rem',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey['900'] : theme.palette.grey['100'],
    overflowY: 'auto',
    maxHeight: '100%'
}))

const GridArticle = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 10rem)',
    overflowY: 'auto',
}))


const GenerateArticle: React.FC = () => {
    const {keywords, chat, article: articleData} = useLoaderData() as {keywords: any[], chat: IChatMessage[], article: ArticleGenerated | null}
    const {userId, chatId} = useParams() as {userId: string, chatId: string}

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [message, setMessage] = React.useState<string>('')
    const chatRef = React.useRef<HTMLUListElement>(null);
    const textareaRef = React.useRef<HTMLTextAreaElement>(null);
    const [popoverKeywordOpen, setPopoverKeywordOpen] = React.useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [keywordToAdd, setKeywordToAdd] = React.useState<string>('')
    const [chatData, setChatData] = React.useState<any[]>([])
    const [article, setArticle] = React.useState<ArticleGenerated | null>(articleData)

    const sendMessage = async (optionSelected?: string) => {
        setIsLoading(true)
        let newChatData = [...chatData, optionSelected || message]
        setChatData(newChatData)
        try{
            const data: iGenerateArticle = {
                message: optionSelected || message,
                external: true,
                dalle: true,
                content_type: ContentTypeGenerateArticle.complete
            }
            setMessage('')
            if (chatId) data['chat_id'] = chatId
            const res = await generateArticleApi(userId, data)
            if (res.status === 200) {
                setChatData([...newChatData, res.data])
                if (res.data.article?.sections){
                    setArticle(res.data.article)
                }
            }
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false)
    }

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            const target = event.target as HTMLTextAreaElement
            switch (target.id) {
                case 'form-add-keyword': handleAddKeyword(); break;
                case 'form-chat-message': {
                    if (!event.shiftKey)  sendMessage();
                    break
                }
            }
        }
    }

    const handleAddKeyword = async () => {
        try{
            const res = await addKeywordApi(userId, keywordToAdd)
            if (res.status === 200) {
                keywords.push(keywordToAdd)
            }
        } catch (error) {
            console.log(error)
        }
        handleClosePopoverKeyword()
    }

    const handleOpenPopoverKeyword = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setPopoverKeywordOpen(true)
    };

    const handleClosePopoverKeyword = () => {
        setPopoverKeywordOpen(false)
        setAnchorEl(null);
        setKeywordToAdd('')
    }

    React.useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [chatData]);


    return (
        <Base name="Generate Article">
        <BoxChatContainer>
            <Grid container component={Paper} sx={{minHeight: '100%', flex: 1}}>
                <Grid item xs={2} key="keywords">
                    <Grid item xs={12} style={{padding: '10px'}}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>

                        <Typography variant="h5" component="div">
                            Keywords
                        </Typography>
                        <Button onClick={handleOpenPopoverKeyword}><LibraryAddIcon /></Button>
                        <Popover
                            open={popoverKeywordOpen}
                            anchorEl={anchorEl}
                            onClose={handleClosePopoverKeyword}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            >
                                <FormGroup sx={{display: 'flex', flexDirection: 'column', padding: '1rem'}}>
                                    <TextField 
                                        id="form-add-keyword" 
                                        label="Keyword" 
                                        sx={{marginBottom: 1}} 
                                        value={keywordToAdd} 
                                        onChange={(e) => setKeywordToAdd(e.target.value)}
                                        onKeyDownCapture={handleKeyPress}
                                    />
                                    <Button variant="contained" onClick={handleAddKeyword}>Add</Button>
                                </FormGroup>
                            </Popover>
                        </Box>
                    </Grid>
                    <Divider />
                    <List sx={{padding: '1rem'}}>
                        {keywords.map((item, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemText >{item}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={article ? 5 : 10} sx={{display: 'flex', flexDirection: 'column', maxHeight: 'calc(100vh - 10rem)'}} key="chat">
                    <List sx={{flex: 1, height: '100%', overflowY: 'auto'}} ref={chatRef}>
                        {chat.map((item, index) => {
                            return (
                                <ListItem key={index} sx={item.role === 'human' ? {display: 'flex', flexDirection: 'row-reverse'}: {}}>
                                    {item.role === 'human' ? 
                                    (<BoxChatHumanMessage>
                                        <ListItemText primary={item.msg}></ListItemText>
                                    </BoxChatHumanMessage>) : 
                                    (<BoxChatIAMessage>
                                        <ListItemText>{item.msg}</ListItemText>
                                    </BoxChatIAMessage>)
                                    }
                                </ListItem>
                                )
                        })}
                        {chatData.map((item, index) => (
                            <ListItem key={index} sx={typeof(item) == 'string' ? {display: 'flex', flexDirection: 'row-reverse'}:{}}>
                                {typeof(item) == 'string' ? 
                                (<BoxChatHumanMessage>
                                    <ListItemText primary={item}></ListItemText>
                                </BoxChatHumanMessage>) : 
                                (
                                    <BoxChatIAMessage>
                                        <>
                                            <ListItemText primary={item.content?.response}></ListItemText>
                                            {item.options.map((option: string, i: number) => (
                                                <Button 
                                                    sx={{margin: 1}}
                                                    variant="contained"
                                                    color="secondary"
                                                    key={i}
                                                    onClick={() => sendMessage(option)}
                                                >{option}</Button>
                                            ))}
                                        </>
                                    </BoxChatIAMessage>
                                )}
                            </ListItem>
                        ))}
                        {isLoading && <ListItem sx={{display: 'flex'}} key={'loading'}>
                            <Typing />
                        </ListItem>}
                        
                        
                    </List>
                    <Divider />
                    <Grid container style={{padding: '20px'}}>
                            <Grid item xs={11}>
                                <TextareaAutosize 
                                    ref={textareaRef}
                                    id="form-chat-message" 
                                    onChange={(e) => setMessage(e.target.value)} 
                                    value={message}
                                    placeholder="Type your message here"
                                    onKeyDownCapture={handleKeyPress} />
                            </Grid>
                            <Grid item xs={1}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={() => sendMessage()}
                                    sx={{height: '100%'}}
                                    disabled={isLoading}
                                    >
                                        <SendIcon />
                                    </Button>
                            </Grid>
                        </Grid>
                </Grid>
                {article && (
                    <GridArticle item xs={5} key="article">
                        <CardArticle variant="outlined">
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{lineHeight: .9, textAlign: 'justify'}}>
                                    {article?.summary}
                                </Typography>
                                <ImageList>
                                    <ImageListItem >
                                        
                                    <img
                                        srcSet={article?.image1?.image}
                                        src={article?.image1?.image}
                                        alt={article?.image1.description}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        subtitle={article?.image1?.description}
                                    />
                                    </ImageListItem>
                                </ImageList>
                                {article?.sections?.map((item: {header: string, text: string}, index: number) => (
                                    <>
                                    <Typography marginTop={2} lineHeight={1} variant="h5" key={index}>{item.header}</Typography>
                                    <TypographyArticle key={index}>{item.text}</TypographyArticle>
                                    </>
                                ))}
                            </CardContent>
                        </CardArticle>
                    </GridArticle>
                )}
            </Grid>
            
        </BoxChatContainer>
        </Base>
    )
}

export default GenerateArticle