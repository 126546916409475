import React, { DragEvent } from "react"
import './DragDrop.css'
import { Button, Input } from "@mui/material";

type iDragDrop = {
    onUpload: (event: FileList) => void,
    files?: FileList | File[],
}


const DragDrop: React.FC<iDragDrop> = ({onUpload, files=[]}) => {
    const drop = React.useRef(null);
    const inputButton = React.useRef<HTMLButtonElement>(null);

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation()
    }

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation()
        onUpload(event.dataTransfer.files)
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = event.target.files;
            onUpload(files)
        }
    };

    const handleClickDragArea = () => {
        if (inputButton?.current) {
            inputButton?.current?.click();
        }
    }

    return (
        <div className="FilesDragAndDrop">

            <div className='FilesDragAndDrop__area' ref={drop} onDrop={handleDrop} onDragOver={handleDragOver}>
            {files.length > 0 ? (
                <div className="FilesDragAndDrop__file">{files[0].name}</div> 
                ):(
                <Button onClick={handleClickDragArea} sx={{width: '100%'}}>
                    Hey, drop me some files here
                </Button>
                )}

                <div className="FilesDragAndDrop__button">
                    <Input type="file" inputRef={inputButton} onChange={handleFileChange} sx={{display: 'none'}} />
                </div>
            </div>
        </div>
    )
}

export default DragDrop