import { Box, styled, Typography } from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';

export const BoxChatHumanMessage = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey['300'],
    color: theme.palette.grey['900'],
    padding: '10px', 
    textAlign: 'right',
    borderRadius: '15px 0px 15px 15px',
    maxWidth: '80%'
}))

export const BoxChatIAMessage = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '10px', 
    textAlign: 'left',
    borderRadius: '0px 15px 15px 15px',
    maxWidth: '80%'
}))

export const BoxChatContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 8rem)',
    alignItems: 'flex-start',
    margin: '1rem',
}))

export const TextareaAutosize = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px 0 0 8px;
    border: 1px solid ${theme.palette.grey[theme.palette.mode === 'dark' ? '600' : '300']};
    background-color: ${theme.palette.background.paper};
    color: ${theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.grey[800]};
    `)

export const TypographyArticle = styled(Typography)(({ theme }) => ({
    textAlign: 'justify',
    textIndent: '2em',
    lineHeight: '1rem'
}))
