import { iGenerateArticle } from "types/generated.type";
import api from "./api";

export const generatePostApi = (data: any) => {
    return api.post('/socialmedia/package', data)
}

export const completionApi = (user_id: string, message: string, chat_Id?: string) => {
    const data: {user_id: string, message: string, chat_id?: string} = {user_id, message}
    if (chat_Id) data['chat_id'] = chat_Id
    const urlParams = new URLSearchParams(data)
    return api.post('completion?'+urlParams)
}


export const generateArticleApi = (userId: string, data: iGenerateArticle) => {
    //message=inteligencia%20artificial&chat_id=vqXvWzrwKf7SvJHWenDw&external=true&dalle=true&content_type=complete
    const dataSend = {
        ...data, external: data.external ? 'true' : 'false',
        dalle: data.dalle ? 'true' : 'false',
        content_type: data.content_type ? data.content_type : 'null'
    }
    const urlParams = new URLSearchParams(dataSend)
    // return {
    //     status: 200,
    //     data: {
    //         "id":"d3e5Q1faQvCkyF4pthQ1",
    //         "role":"ai",
    //         "content":{
    //            "response":"Each title offers a unique perspective on Necto's impact and innovations in the tech industry. Whether you're interested in their revolutionary approach, looking for a guide to their top innovations, seeking insights into their leadership in Python tools, wondering about their essential role in 2023, or searching for a comprehensive list of their best software solutions, there's a title that caters to your curiosity. Choose the one that resonates most or mix elements to create your own captivating headline.",
    //            "images_url":[
                  
    //            ],
    //            "images":[
                  
    //            ]
    //         },
    //         "options":[
    //            "How Necto Revolutionized Software in 2023",
    //            "5 Innovations by Necto: A 2023 Guide",
    //            "Why Necto Leads in Python Tools: 2023 Insights",
    //            "What Makes Necto Essential in 2023?",
    //            "Guide to Necto's Top 5 Software Solutions"
    //         ],
    //         "article":{
    //            "keywords":[
    //               "gere um artigo sobre a necto"
    //            ],
    //            "title":null,
    //            "question":null,
    //            "subtitle":null,
    //            "image1":null,
    //            "sections":null,
    //            "footer":null,
    //            "summary":null,
    //            "answer":null,
    //            "image2":null,
    //            "meta_desc":null,
    //            "tags":null
    //         }
    //      }
    // }
    return api.post(`${userId}/content/article?${urlParams}`)
}

export const getArticleFromChatApi = (userId: string, chatId: string) => {
    return api.get(`/users/${userId}/chats/${chatId}?field=article`)
}
