
import React from 'react';
import Grid from '@mui/material/Grid';
import Base from '../Base';
import ProfileCard from 'components/ProfileCard';
import { Box, Button } from '@mui/material';
import { useNavigate, useLoaderData } from 'react-router-dom';
import { User } from 'types/user.type';

const Profiles = () => {
    const navigate = useNavigate();
    const users = useLoaderData() as User[]


    const gotToNewProfile = () => {
        navigate('/profile')
    }

    
    return (
        <Base name="Profiles" noBackButton>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', paddingY: 2, paddingX: 10 }}>
                <Button variant="contained" onClick={gotToNewProfile}>Add Profile</Button>
            </Box>
            <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {users.map((user: User, index: number) => (
                    <Grid item lg={3} md={6} xs={12} key={index}>
                    <ProfileCard key={index} user={user}></ProfileCard>
                    </Grid>
                ))}
            </Grid>
        </Base>
    )
}

export default Profiles