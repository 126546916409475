import { iProfile, User } from "types/user.type";
import { getBusinessProfileApi, getChatHistoryApi, getChatsApi, getKeywordsApi, getUserById, getUserFilesApi, getUsersApi } from "services/users";
import { IChatMessage } from "types/chat.type";
import { getArticleFromChatApi } from "services/generate";
import { ArticleGenerated } from "types/generated.type";

export const usersLoader = async (): Promise<User[]> => {
    try {
        const res = await getUsersApi();
        return res.data;
    } catch (error) {
        throw new Response("Failed to load users", { status: 500 });
    }
};

export const userLoader = async (userId: string): Promise<{profile: iProfile, business_profile: any}> => {
    try {
        const res = await getUserById(userId);
        const resBusinessProfile = await getBusinessProfileApi(userId);
        return {
            profile: {id: userId, ...res.data},
            business_profile: resBusinessProfile.data
        };
    } catch (error) {
        throw new Response("Failed to load user", { status: 500 });
    }
};

export const userDataLoader = async (userId: string): Promise<any> => {
    if (!userId || userId === '') return []
    try {
        const res = await getUserFilesApi(userId);
        return {files: res.data};
    } catch (error) {
        throw new Response("Failed to load users", { status: 500 });
    }
};

export const userChatLoaders = async (userId: string): Promise<any> => {
    if (!userId || userId === '') return []
    try {
        const res = await getChatsApi(userId);
        return res.data;
    } catch (error) {
        throw new Response("Failed to load users", { status: 500 });
    }
}

export const userChatKeyworkdsLoaders = async (userId: string, chatId?: string): Promise<any> => {
    if (!userId || userId === '') return []
    try {
        const res = await getKeywordsApi(userId);
        const keywords = res.data
        let chat: IChatMessage[] = []
        let article: ArticleGenerated | null = null
        if (chatId) {
            const resChat = await getChatHistoryApi(userId, chatId)
            const articleData = await getArticleFromChatApi(userId, chatId)
            chat = resChat.data
            article = articleData.data
        }
        return {keywords, chat, article};
    } catch (error) {
        throw new Response("Failed to load users", { status: 500 });
    }
}