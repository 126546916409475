import Cookies from 'universal-cookie';

import { Navigate, Outlet, useNavigation } from "react-router-dom";
import { Box, LinearProgress } from '@mui/material';
import Base from './Base';

const ProtectedRoutes = () => {
	const cookies = new Cookies(null, { path: '/' });
	const cookieToken = cookies.get("iamarketing-user-token");
	const {state} = useNavigation()

	if (state === "loading") return <Base><Box><LinearProgress /></Box></Base>

	return cookieToken ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoutes;