import React, { createContext, useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const ThemeContext = createContext({ theme: 'light', setTheme: (theme: 'light' | 'dark') => {} });

const defaultTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#0b488a',
        },
    },
});
const darkTheme = createTheme({ palette: { mode: 'dark' } });

const cookietheme = cookies.get('iamarketing-theme') || 'light';

export const ThemeContextProvider: React.FC<{children: string | JSX.Element | JSX.Element[]}> = ({ children }) => {
    const [theme, setTheme] = React.useState<'light' | 'dark'>(cookietheme); // Tema inicial

    React.useEffect(() => {
        const theme = cookies.get('iamarketing-theme') || 'light';
        setTheme(theme)
    }, [])

    React.useEffect(() => {
        cookies.set('iamarketing-theme', theme, { path: '/' });
    }, [theme])


    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            <ThemeProvider theme={theme === 'dark' ? darkTheme : defaultTheme}>
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
