import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';


type iMenu = {
    toggleDrawer: (open: boolean) => void
}

const Menu: React.FC<iMenu> = ({toggleDrawer}) => {
    const navigate = useNavigate();
    const siteMap = [
        {name: 'Profiles', path: '/'},
        {name: 'Chat', path: '/'}
    ]

    const navigateTo = (path: string) => {
        navigate(path)
    }
    return (
        
    <Box sx={{ width: 250 }} role="presentation" onClick={() => toggleDrawer(false)}>
        <List>
            <Typography variant="h6" sx={{textAlign: 'center'}}>IAMarketing</Typography>
            <Divider />
            {siteMap.map((page, index) => (
            <ListItem key={index} disablePadding>
                <ListItemButton onClick={() => navigateTo(page.path)}>
                <ListItemText primary={page.name} />
                </ListItemButton>
            </ListItem>
            ))}
        </List>
    </Box>
    )
};

export default Menu