export type GeneratedData = {
    body: string,
    caption: {
        longcaption: string,
        shortcaption: string
    },
    title?: string
}

export interface GeneratedDataMeme extends GeneratedData {
    image: string,
    meme: string
}

export interface GeneratedDataFunFacts extends GeneratedData {
    funfact: string
}

export interface GeneratedDataStories extends GeneratedData {
    story: string
}

export interface GeneratedDataCalToActions extends GeneratedData {
    calltoaction: string
}

export interface GeneratedDataTestimonials extends GeneratedData {
    testimonial: string
}

export interface GeneratedDataQuotes extends GeneratedData {
    author: string,
}

export interface GeneratedDataEdCarrousels extends GeneratedData {
    title_slide: string,
    body_slides: string[],
    ending_slide: string
}


export type Generated = {
    [key: string]: GeneratedData[]
}

export enum ContentTypeGenerateArticle {
    complete = 'complete',
    extracted = 'extracted',
}

export type ArticleGenerated = {
    "keywords":string[],
    "title":any,
    "question":any,
    "subtitle":any,
    "image1":any,
    "sections":any,
    "footer":any,
    "summary":any,
    "answer":any,
    "image2":any,
    "meta_desc":any,
    "tags":any
}
export type iGenerateArticle = {
    message: string
    chat_id?: string
    external: boolean
    dalle: boolean
    content_type: ContentTypeGenerateArticle | null
}

export type GeneratedArticle = {
    id: string
    role: string
    content: {response: string, images_url: string[], images: string[]}
    options: string[]
    article: ArticleGenerated
}