import api from "./api";
import Cookies from 'universal-cookie';

export const loginService = (email: string, password: string) => {
    const cookies = new Cookies(null, { path: '/' });
    return api.post("/auth/signin", { email, password }).then(res => {
        if (res.status === 200 ) {
            cookies.set('iamarketing-user-token', res.data.token, { path: '/' });
        }
        return res
    });
}