import React from "react"
import { Box, Button, Divider, MobileStepper, Typography } from "@mui/material"
import { GeneratedDataEdCarrousels } from "types/generated.type"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"

type iGeneratedCard = {
    data?: GeneratedDataEdCarrousels,
    slides_captions?: string[]
}

const GeneratedDataCardEdCarrousels: React.FC<iGeneratedCard> = ({data}) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const slides: string[] = [data?.title_slide || ''].concat(data?.body_slides || []).concat(data?.ending_slide || []);
    const maxSteps = slides.length || 0;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    

    return (
        <Box mb={2}>
            <Typography variant="h4" component="div" sx={{fontWeight: 700}}>
                {slides[activeStep]}
            </Typography>
            <MobileStepper
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                >
                    Next
                    <KeyboardArrowRight />
                </Button>
                }
                backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    <KeyboardArrowLeft />
                    Back
                </Button>
                }
            />
            <Divider sx={{my: 1.2}}/>
            <Typography component="div" sx={{textAlign: 'justify', textIndent: '50px'}}>
                {data?.caption?.longcaption}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {data?.caption?.shortcaption}
            </Typography>
            <Typography variant="body2">
                {data?.body}
            </Typography>
        </Box>
    )
}

export default GeneratedDataCardEdCarrousels