import React from "react"
import GeneratedCard from "./GeneratedCard"
import { GeneratedData } from "types/generated.type"
import { Box, Card, CardContent, CardHeader, Chip, Divider, Grid, styled } from "@mui/material"
import * as Colors from '@mui/material/colors'
import GeneratedDataCardMeme from "./GeneratedPostData/GeneratedDataCardMeme"
import GeneratedDataCardFunFact from "./GeneratedPostData/GeneratedDataCardFunFact"
import GeneratedDataCardStories from "./GeneratedPostData/GeneratedDataCardStories"
import GeneratedDataCardEdCarrousels from "./GeneratedPostData/GeneratedDataCardEdCarrousels"
import GeneratedDataCardCallToActions from "./GeneratedPostData/GeneratedDataCardCallToActions"
import GeneratedDataCardQuote from "./GeneratedPostData/GeneratedDataCardQuote"

type iGeneratedGroupData = {
    data: GeneratedData[],
    tag?: string
}

const StyledChips = styled(Chip)(({ theme }) => ({
    fontWeight: 700,
    fontSize: '1.2rem',
    paddingX: 1.5,
    paddingY: 2,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.grey[800],
    color: theme.palette.mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
}))

const GeneratedGroupData: React.FC<iGeneratedGroupData> = ({data, tag=''}) => {
    const CARD_TITILES: {[key: string]: {label: string, color: string, component?: React.FC<any>}} = {
        'memes': {label: 'Memes', color: Colors.red[500], component: GeneratedDataCardMeme},
        'funfacts': {label: 'Fun Facts', color: Colors.blue[500], component: GeneratedDataCardFunFact},
        'benefitsfeatures': {label: 'Benefits & Features', color: Colors.green[500]},
        'qandas': {label: 'Q&A', color: Colors.purple[500]},
        'stories': {label: 'Humanity Stories', color: Colors.orange[500], component: GeneratedDataCardStories},
        'edcarrousels': {label: 'Educational Carrousels', color: Colors.pink[500], component: GeneratedDataCardEdCarrousels},
        'calltoactions': {label: 'Call to Actions', color: Colors.yellow[500], component: GeneratedDataCardCallToActions},
        'testimonials': {label: 'Testimonials', color: Colors.teal[500]},
        'quotes': {label: 'Quotes', color: Colors.indigo[500], component: GeneratedDataCardQuote},
        'reelcarrousels': {label: 'Reel Carrousels', color: Colors.lime[500]},
        'inspirationalcarrousels': {label: 'Inspirational Carrousels', color: Colors.cyan[500]},
        'videoCarrousels': {label: 'Video Carrousels', color: Colors.deepPurple[500]},
    }
    
    const ChipCardHeader: React.FC<{tag: string}> = ({tag}) => (
        <StyledChips label={CARD_TITILES[tag]?.label || ''} />
    )

    const getComponent = (tag: string, item: GeneratedData): JSX.Element => {
        const Component = CARD_TITILES[tag]?.component;
    
        return Component ? <Component data={item} /> : <GeneratedCard data={item} />;
    };

    return (
        <Grid container sx={{paddingTop: 2}} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={10} lg={8}>
                <Card variant="outlined" sx={{ mb:2, borderColor: CARD_TITILES[tag]?.color}}>
                    <CardHeader title={<ChipCardHeader tag={tag} />} sx={{backgroundColor: CARD_TITILES[tag]?.color}}/>
                    <CardContent>
                        {data.map((item, index) => {
                            return (
                            <Box key={index}>
                                {index > 0 && <Divider sx={{marginBottom: 2}} />}
                                {getComponent(tag, item)}
                            </Box>)
                        })}
                        
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default GeneratedGroupData