import axios, { AxiosError, AxiosResponse } from "axios";
import Cookies from 'universal-cookie';

type ApiError = {
    detail: string
}

const cookies = new Cookies();

const getToken = () => {
    return cookies.get('iamarketing-user-token')
}

const axiosHttp = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

axiosHttp.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// interceptors response
axiosHttp.interceptors.response.use(
    (res: AxiosResponse) => {
      /// Do something with response
      return res;
    },
    (error: AxiosError<ApiError>) => {
      // sem autorização
      if (error.response?.status === 401 || error.response?.status === 0) {
        window.location.href = "/login";
      }
      if (error.response?.status === 400 && error.response?.data?.detail === "TokenID must be provided") {
        window.location.href = "/login";
      }
      return Promise.reject(error);
    },
  );
  

export default axiosHttp;