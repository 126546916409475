import { styled } from '@mui/material/styles';
import { Box, Typography, keyframes } from "@mui/material"

const typing = keyframes`
  from { width: 0 }
  to { width: 100% }
`
const TypingLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '.8rem',
}))
const TypingEffect = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    animation: `${typing} 1s steps(30, end) infinite`,
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    letterSpacing: '.15em',
}))

const Typing = () => {
    return (
        <>
            <TypingLabel variant="h6">Typing</TypingLabel>
            <Box>
                <TypingEffect>...</TypingEffect>
            </Box>
        </>
    )
}

export default Typing;