import React from 'react';
import { 
    Box, Button, Grid, TextField, Switch, FormGroup, 
    FormLabel, CircularProgress} from "@mui/material"
import Base from "../Base"
import { generatePostApi } from "services/generate"
import { useLoaderData, useParams } from "react-router-dom";
import { Generated } from 'types/generated.type';
import GeneratedGroupData from 'components/GeneratedGoupData';


type iSwitch = {
    value: boolean,
    name: string
}

type iValuesSwtich = {
    [key: string]: iSwitch
}


const GeneratePost: React.FC = () => {
    let { userId, chatId } = useParams();
    const {files} = useLoaderData() as {files: string[]};

    const switchesValues: iValuesSwtich = {
        generate_quotes: {value: false, name: "Quotes"},
        generate_memes: {value: false, name: "Memes"},
        generate_funfacts: {value: false, name: "Fun Facts"},
        generate_benefitsfeatures: {value: false, name: "Benefits & Features"},
        generate_QandA: {value: false, name: "Q&A"},
        generate_humanityStories: {value: false, name: "Humanity Stories"},
        generate_edCarrousel: {value: false, name: "Educational Carousel"},
        generate_ctas: {value: false, name: "Call To Actions"},
        generate_testimonial_reel: {value: false, name: "Testimonial Reel"},
        generate_testimonial_post: {value: false, name: "Testimonial Post"},
        generate_inspirational: {value: false, name: "Inspirational"},
        generate_reel_canvas: {value: false, name: "Reel Canvas"},
        generate_videos_benefits: {value: false, name: "Videos & Benefits"}
    }

    const [values, setValues] = React.useState<iValuesSwtich>(switchesValues)
    const [generated, setGenerated] = React.useState<Generated | null>(null)
    const [loading, setLoading] = React.useState<boolean>(false)

    const handleChangeSwitchValue = (switchValue: boolean, field: string) => {
        setValues({...values, [field]: {...values[field], value: switchValue}})
    }

    const handleGeneratePost = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true)
        const formData = new FormData(event.currentTarget);
        const tone = formData.get('tone') as string;
        const locations = formData.get('locations') as string;
        const switchs = Object.keys(values).filter((key: string) => values[key].value)
        const data = {
            files: files,
            ctas: [], //["visit our site", "Follow on Social Media", "Check out Blog", "Download App/eBook", "Newsletter"],
            locations: locations.split(',').map((location: string) => location.trim()),
            posts: switchs.map((switchValue: string) => {
                const postData: any = {
                    post_type: switchValue,
                    tone: tone,
                    user_id: userId,
                    special: ""
                }
                if (chatId) {
                    postData['chat_id'] = chatId
                }
                return postData
            })
        }

        try{
            const res = await generatePostApi(data);
            setGenerated(res.data)
        } catch(err) {
            console.error('err', err)
        }

        setLoading(false)
    }


    return (
        <Base name="Generate Post">
            {generated ? (
                <>
                <Box sx={{alignItems: 'end', display: 'flex', flexDirection: 'column', paddingX: 5}}>
                    <Button variant='contained' onClick={() => setGenerated(null)}>Generate Again</Button>
                </Box>
                <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                    {Object.keys(generated)
                        .filter((key: string) => !['questions', 'slides_captions'].includes(key))
                        .map(key => <GeneratedGroupData key={key} data={generated[key]} tag={key}/>)}
                </Box>
                </>
            )  : (
            <Box component="form" onSubmit={handleGeneratePost}
                sx={{
                    padding: 5,
                    paddingTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
                >
                <Grid container spacing={4}>
                    {Object.keys(values).map((key: string, index: number) => (
                        <Grid item xs={12} sm={6} md={2} key={index}>
                            <Button 
                                fullWidth
                                sx={{height: '100%'}}
                                variant={values[key].value ? "contained" : "outlined"} 
                                onClick={() => handleChangeSwitchValue(!values[key].value, key)}>
                                {values[key].name}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
                <Grid container spacing={4} marginTop={1}>
                    <Grid item xs={12} md={8}>
                        <TextField fullWidth id="tone-field" label="Tone" variant="outlined" name="tone"/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField type="number" defaultValue={1} fullWidth id="number-field" label="Number of posts" variant="outlined" name="number_of_posts"/>
                    </Grid>
                </Grid>
                <Grid container spacing={4} marginTop={1}>
                    <Grid item xs={12} md={2}>
                        <FormLabel>Location Business</FormLabel>
                        <FormGroup>
                            <Switch defaultChecked name="location_business"/>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <TextField fullWidth id="locations-field" label="Locations" variant="outlined" name="locations"
                            helperText="Enter the locations separated by commas"/>
                    </Grid>
                </Grid>
                <Box sx={{alignItems: 'end', display: 'flex', flexDirection: 'column', paddingRight: 15, width: '100%'}}>
                    <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} disabled={loading}>
                        {loading ? (
                            <>
                            Loading <CircularProgress color="inherit" size={18} sx={{marginLeft: 1}}/>
                            </>
                        ): <>Generate</> }
                    </Button>
                </Box>
            </Box>
            )}
        </Base>
    )
}

export default GeneratePost