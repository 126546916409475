import React from "react"
import { Box, Divider, Typography } from "@mui/material"
import { GeneratedDataQuotes } from "types/generated.type"

type iGeneratedCard = {
    data?: GeneratedDataQuotes,
}

const GeneratedDataCardQuote: React.FC<iGeneratedCard> = ({data}) => {

    return (
        <Box mb={2}>
            <Typography variant="h4" component="div" sx={{fontWeight: 700}}>
                "{data?.body}" - {data?.author}
            </Typography>
            <Divider sx={{my: 1.2}}/>
            <Typography component="div" sx={{textAlign: 'justify', textIndent: '50px'}}>
                {data?.caption?.longcaption}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {data?.caption?.shortcaption}
            </Typography>
        </Box>
    )
}

export default GeneratedDataCardQuote