import React from 'react';
import { 
    Box, Button, Divider, Grid, List, ListItem, 
    ListItemText, Paper, Typography,
} from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Base from 'pages/Base';
import { Link, useLoaderData, useParams } from 'react-router-dom';
import { createChatApi } from 'services/users';
import { IChat, IChatMessage, IDictChats, Role } from 'types/chat.type';
import { completionApi } from 'services/generate';
import Typing from 'components/Typing';
import { 
    BoxChatContainer, BoxChatHumanMessage, BoxChatIAMessage, TextareaAutosize,
    TypographyArticle 
} from 'pages/styled/Chat.styled';



const Chat = () => {
    const chats = useLoaderData() as IDictChats
    const firstChat = Object.keys(chats).length ? chats[Object.keys(chats)[0]] : null
    const firstChatId = Object.keys(chats).length ? Object.keys(chats)[0] : ''
    const  { userId } = useParams()
    const [message, setMessage] = React.useState<string>('')
    const [activeChat, setActiveChat] = React.useState<IChat | null>(firstChat)
    const [activeChatId, setActiveChatId] = React.useState<string>(firstChatId)
    const chatRef = React.useRef<HTMLUListElement>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const handleActiveChatChange = (key: string) => {
        setActiveChatId(key)
        setActiveChat(chats[key])
    }

    const formatDate = (date: string) => {
        try{
            const d = new Date(date)
            return `${d.getDate()}/${d.getMonth()}/${d.getFullYear()}`
        } catch(err) {
            console.error('err', err)
            return 'Invalid Date'
        }
    }
    
    const sendMessage = async () => {
        const data: IChatMessage = {
            msg: message,
            role: Role.human
        }
        const active = activeChat as IChat
        const activeHistory = [...active.history]
        setActiveChat({ ...active, history: activeHistory.concat(data) })
        setMessage('')
        setIsLoading(true)
        try{
            const res = await completionApi(userId || '', message, activeChatId)
            const messageResponse: IChatMessage = {
                msg: res.data.content.response,
                role: Role.ai
            }
            chats[activeChatId] = {...active, updated_at: new Date().toString()}
            setActiveChat({ ...active, history: activeHistory.concat(data, messageResponse) })
        } catch(err) {
            console.error('err', err)
        }
        setIsLoading(false)
    }

    const createChat = () => {
        createChatApi(userId || '').then((res: any) => {
            if (res.status === 200) {
                chats[res.data.id] = {...res.data, updated_at: new Date().toString()}
                setActiveChat(res.data)
                setActiveChatId(res.data.id)
            }
        })
        setMessage('')
    }

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            sendMessage();
        }
    };

    React.useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [activeChat]);

    return (
        <Base name="Chat">
            <BoxChatContainer sx={{paddingTop: 1}}>
                
                <Grid container component={Paper} sx={{minHeight: '100%', flex: 1}}>
                    <Grid item xs={2} key="chats">
                        <Grid item xs={12} style={{padding: '10px'}}>
                            <Button onClick={createChat}><LibraryAddIcon /></Button>
                            
                        </Grid>
                        <Divider />
                        <List>
                            {Object.keys(chats).map((key: string, index: number) => {
                                let chat = chats[key] as IChat
                                return (
                                <ListItem key={index} disablePadding>
                                    <Button 
                                        variant={activeChatId === key ? 'outlined' : 'text'}
                                        onClick={() => handleActiveChatChange(key)}
                                        sx={{display: 'flex', justifyContent: 'left', width: '100%'}}
                                    >
                                        <ListItemText secondary={formatDate(chat.updated_at)} ></ListItemText>
                                    </Button>
                                </ListItem>
                            )})}
                        </List>
                    </Grid>
                    <Grid item xs={activeChat?.article ? 5 : 10} sx={{display: 'flex', flexDirection: 'column', maxHeight: 'calc(100vh - 10rem)'}} key="chat">
                        {activeChat && (<Box padding={2}>

                            <Link to={`/generate/${userId}/article/${activeChatId}`}>
                                <Button variant="contained" sx={{marginX: 2}}>Generate article</Button>
                            </Link>
                            <Link to={`/generate/${userId}/post/${activeChatId}`}>
                                <Button variant="contained" sx={{marginX: 2}}>Generate Post</Button>
                            </Link>
                        </Box>) }
                        <List sx={{flex: 1, maxHeight: '100%', overflowY: 'auto'}} ref={chatRef}>
                            {activeChat?.history.map((item, index) => {
                                return (
                                    <ListItem key={index} sx={item.role === 'human' ? {display: 'flex', flexDirection: 'row-reverse'}: {}}>
                                    {item.role === 'human' ? 
                                        (<BoxChatHumanMessage>
                                            <ListItemText primary={item.msg}></ListItemText>
                                        </BoxChatHumanMessage>) : 
                                        (<BoxChatIAMessage>
                                            <ListItemText>{item.msg}</ListItemText>
                                        </BoxChatIAMessage>)
                                    }
                                    </ListItem>
                                )
                            })}
                            {isLoading && (<ListItem sx={{display: 'flex'}} key={'loading'}>
                                <Typing />
                            </ListItem>) }
                        </List>
                        <Divider />
                        {activeChat && (
                            <Grid container style={{padding: '20px'}}>
                                <Grid item xs={11}>
                                    <TextareaAutosize 
                                        onChange={(e) => setMessage(e.target.value)} 
                                        value={message}
                                        placeholder="Type your message here"
                                        onKeyDownCapture={handleKeyPress} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={sendMessage}
                                        sx={{height: '100%'}}
                                        >
                                            <SendIcon />
                                        </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    {activeChat?.article && (
                        <Grid item xs={5} padding={2} key="article" sx={{display: 'flex', flexDirection: 'column', maxHeight: 'calc(100vh - 10rem)', overflowY: 'auto'}}>
                            <Typography variant="h5">Article</Typography>
                            <TypographyArticle>{activeChat?.article.answer}</TypographyArticle>
                            <TypographyArticle>{activeChat?.article.summary}</TypographyArticle>
                            <TypographyArticle>{activeChat?.article.footer}</TypographyArticle>
                        </Grid>
                    )}
                </Grid>
            </BoxChatContainer>
        </Base>
    );
}

export default Chat;