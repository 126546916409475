import React from "react";
import { 
    Box, Button, FormControl, Grid, InputLabel, 
    MenuItem, Select, TextField, Typography 
} from "@mui/material"
import Base from "pages/Base"
import { businessProfileApi, createProfileApi, updateProfileApi } from "services/users";
import { useLoaderData, useNavigate } from "react-router-dom";
import DragDrop from "../../components/DragDrop";
import { AxiosResponse } from "axios";
import BusinessProfile from "components/BusinessProfile";
import { iBusinessProfile } from "types/business.type";
import { iProfile } from "types/user.type";
import { ContrastTextBox } from "pages/styled/Common.styled";



const ProfileForm: React.FC = () => {
    const navigate = useNavigate();
    const profileData = useLoaderData() as {profile: iProfile, business_profile: iBusinessProfile}
    const businessProfile = profileData?.business_profile
    const [files, setFiles] = React.useState<FileList | File[]>([]);
    const [profile, setProfile] = React.useState<iProfile>(profileData?.profile || {
        name: '',
        email: '',
        profile_type: '',
        webhook: [{
            webhook: '',
            name: '',
        }],
    });
    const types = [
        {label: 'Personal', value: 'personal'},
        {label: 'Business', value: 'business'},
    ]

    const onSendData = (res: AxiosResponse) => {
        if (!files.length) {
            navigate('/')
            return
        }
        const id = profile?.id || res.data.id
        const formData = new FormData();
        formData.append('file', files[0]);
        businessProfileApi(id, formData).then(() => {
            console.log('success', res.data)
            navigate('/')
        }).catch(err => {
            console.log('err', err)
        })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const urlParams = new URLSearchParams({
            name: profile?.name,
            email: profile?.email,
            profile_type: profile?.profile_type,
        }).toString();
        const body = profile?.webhook
        if (profile?.id){
            updateProfileApi(profile?.id, urlParams, body).then(onSendData)
        } else {
            createProfileApi(urlParams, body[0]).then((res) => {
                setProfile({
                    ...profile,
                    id: res.data.id
                })
                onSendData(res)
            }).catch(err => {
                console.log('err', err.response)
            })
        }
    }

    const handleUpload = (files: FileList) => {
        setFiles(files)
    }


    return (
        <Base name="Profile">

            <Box
                sx={{
                    padding: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
                >
                <Grid container>
                    <Grid item xs={12} md={businessProfile ? 4 : 12}>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                            <Grid container columnSpacing={1} spacing={{ xs: 2, md: 3 }}>
                                <Grid item xs={12} md={businessProfile ? 12 : 9}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Name"
                                        value={profile?.name || ''}
                                        onChange={(e) => setProfile(() => ({...profile, name: e.target.value}))}
                                        name="profileName"
                                        autoFocus
                                    />
                                    <Grid container columnSpacing={1} spacing={{ xs: 2, md: 3 }} marginBottom={5}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                label="Email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                value={profile?.email || ''}
                                                onChange={(e) => setProfile(() => ({...profile, email: e.target.value}))}
                                                />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth margin="normal">
                                                <InputLabel id="select-type">Type</InputLabel>
                                                <Select
                                                    labelId="select-type"
                                                    id="demo-simple-select"
                                                    name="type"
                                                    value={profile?.profile_type || ''}
                                                    onChange={(e) => setProfile(() => ({...profile, profile_type: e.target.value}))}
                                                    label="Type"
                                                >
                                                    {types.map((t, i) => (
                                                        <MenuItem key={i} value={t.value}>{t.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <ContrastTextBox>
                                        <Typography component="h1" variant="h5">
                                            Webhook
                                        </Typography>
                                    </ContrastTextBox>
                                    <Grid container columnSpacing={1} spacing={{ xs: 2, md: 3 }}>
                                        <Grid item xs={12} md={6}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="webhook_name"
                                            label="Name"
                                            name="webhook_name"
                                            value={profile?.webhook[0]?.name || ''}
                                            onChange={(e) => setProfile(() => ({...profile, webhook: [{...profile?.webhook[0], name: e.target.value}]}))}
                                            autoComplete="webhook"
                                        />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="webhook_url"
                                            label="Url"
                                            value={profile?.webhook[0]?.webhook || ''}
                                            onChange={(e) => setProfile(() => ({...profile, webhook: [{...profile?.webhook[0], webhook: e.target.value}]}))}
                                            name="webhook_url"
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={businessProfile ? 12 : 3}>
                                    <DragDrop onUpload={handleUpload} files={files}></DragDrop>
                                </Grid>
                            </Grid>
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                Save
                            </Button>
                        </Box>
                        </Grid>
                        
                            {businessProfile && (
                                <Grid item xs={12} md={8}>
                                    <Box sx={{paddingX: 5}}>
                                        <BusinessProfile profile={businessProfile}></BusinessProfile>
                                    </Box>
                                </Grid>
                            )}
                </Grid>
            </Box>
            
        </Base>
    )
}

export default ProfileForm