import { MessageData } from "types/user.type";
import api from "./api";

type iWebhookData = {
    webhook: string
    name: string
}

export const getUsersApi = () => {
    return api.get('/users/get_all')
}

export const getUserById = (id: string) => {
    return api.get('/users/'+id)
}

export const createProfileApi = (urlParams: string, data: iWebhookData) => {
    return api.post('/users?'+urlParams, data)
}

export const updateProfileApi = (id: string, urlParams: string, data: iWebhookData[]) => {
    return api.post('/users/'+id+'/update?'+urlParams, data)
}

export const businessProfileApi = (id: string, fileFormFormData: FormData) => {
    const headers = { "Content-Type": "multipart/form-data" }
    return api.post('/users/'+id+'/business_profile', fileFormFormData, {headers})
}

export const getBusinessProfileApi = (id: string) => {
    return api.get('/users/'+id+'/business_profile')
}

export const getUserFilesApi = (userId: string) => {
    return api.get(`/users/${userId}/files`)
}

export const getChatsApi = (userId: string) => {
    return api.get(`/users/${userId}/chats`)
}

export const createChatApi = (userId: string) => {
    return api.post(`/users/${userId}/chats`)
}

export const getChatHistoryApi = (userId: string, chatId: string) => {
    return api.get(`/users/${userId}/chats/${chatId}/history`)
}


export const sendChatMessageApi = (userId: string, chatId: string, data: MessageData) => {
    return api.put(`/users/${userId}/chats/${chatId}`, data)
}

export const addKeywordApi = (userId: string, keywords: string) => {
    const urlParams = new URLSearchParams({keywords})
    return api.post(`users/${userId}/keywords?${urlParams}`)
}

export const getKeywordsApi = (userId: string) => {
    return api.get(`users/${userId}/keywords`)
}