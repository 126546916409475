
export type ChatArticle = {
    answer: string,
    summary: string,
    footer: string
}

export enum Role {
    human = 'human',
    ai = 'ai'
}
export type IChatMessage = {
    images_url?:	string[],
    msg: string,
    role: Role,
}

export type IChat = {
    title: string,
    updated_at: string,
    current_key: string,
    history: IChatMessage[],
    article: ChatArticle
}

export type IDictChats = {
    [key: string]: IChat
}