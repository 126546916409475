import React from "react"
import {Typography, Link, Card, CardContent} from '@mui/material'
import { iBusinessProfile } from "../types/business.type"
import { ContrastTextBox } from "pages/styled/Common.styled"


type IBusinessProfileComponent = {
    profile?: iBusinessProfile
}

const BusinessProfile: React.FC<IBusinessProfileComponent> = ({profile}) => {
    return (
        <ContrastTextBox>
            <Card>
                <CardContent>
                    <Typography fontWeight={700} variant="h5">Cultura</Typography>
                    <Typography variant="h6">Marca:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Culture?.brand}</Typography>

                    <Typography variant="h6">Missão:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Culture?.mission}</Typography>

                    <Typography variant="h6">Posicionamento:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Culture?.positioning}</Typography>

                    <Typography variant="h6">Propósito:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Culture?.purpose}</Typography>
                </CardContent>
            </Card>

            <Card sx={{marginTop: 2}}>
                <CardContent>
                    <Typography fontWeight={700} variant="h5">Produto</Typography>
                    <Typography variant="h6">Produtos:</Typography>
                    {profile?.Product?.products?.map((product, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{product}</Typography>
                    ))}
                    
                    <Typography variant="h6">Proposta de valor:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Product?.value_proposition}</Typography>

                    <Typography variant="h6">Industria:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Product?.industry}</Typography>

                    <Typography variant="h6">Pontos de dor:</Typography>
                    {profile?.Product?.pain_points?.map((painPoints, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{painPoints}</Typography>
                    ))}

                    <Typography variant="h6">História de negócios:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Product?.business_story}</Typography>

                    <Typography variant="h6">Tipo de conteúdo:</Typography>
                    {profile?.Product?.content_type?.map((content, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{content}</Typography>
                    ))}
                </CardContent>
            </Card>
            <Card sx={{marginTop: 2}}>
                <CardContent>
                    <Typography fontWeight={700} variant="h5">Negócios</Typography>
                    <Typography variant="h6">Mercado alvo:</Typography>
                    {profile?.Business?.target_market?.map((target, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{target}</Typography>
                    ))}

                    <Typography variant="h6">Website:</Typography>
                    <Link sx={{marginLeft: 2}} href={'http://' + profile?.Business?.website || '#'} target="_blank">{profile?.Business?.website}</Link>
                </CardContent>
            </Card>
            <Card sx={{marginTop: 2}}>
                <CardContent>

                    <Typography fontWeight={700} variant="h5">Concorrentes</Typography>

                    <Typography variant="h6">Concorrentes:</Typography>
                    {profile?.Competitors?.competitors?.map((competitors, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{competitors}</Typography>
                    ))}

                    <Typography variant="h6">Esforço:</Typography>
                    {profile?.Competitors?.efforts?.map((effort, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{effort}</Typography>
                    ))}
                </CardContent>
            </Card>

            <Card sx={{marginTop: 2}}>
                <CardContent>
                    <Typography fontWeight={700} variant="h5">Atualmente</Typography>
                    <Typography variant="h6">SEO:</Typography>
                    <Typography sx={{marginLeft: 2}}>{profile?.Current?.SEO}</Typography>

                    {/* <Typography variant="h6">Esforços atuais:</Typography>
                    {profile?.Current?.current_efforts?.map((effort, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{effort}</Typography>
                        ))} */}

                    <Typography variant="h6">Trabalhos atuais:</Typography>
                    {profile?.Current?.whats_working?.map((work, index) => (
                        <Typography sx={{marginLeft: 2}} key={index}>{work}</Typography>
                    ))}
                </CardContent>
            </Card>

            <Card sx={{marginTop: 2}}>
                <CardContent>
                    <Typography fontWeight={700} variant="h5">Objetivos</Typography>
                    <Typography variant="h6">Objetivos:</Typography>
                    <Typography sx={{marginLeft: 2}} >{profile?.Goals?.goals}</Typography>
                </CardContent>
            </Card>
        </ContrastTextBox>
    )
} 
export default BusinessProfile